<template>
  <Home />
</template>

<script>
import Home from "./Home";
import Preview from "./Preview";
export default {
  name: "Index",
  components: {
    [Home.name]: Home,
    [Preview.name]: Preview,
  },
};
</script>
<style >
</style>
