import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
const routes = [{
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/rules',
        name: 'Rules',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Rules.vue')
    },
    {
        path: '/notices/:code',
        name: 'Notices',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Notices.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const wechatLogin = (to) => {
    let href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.APP_ID}&redirect_uri=${process.env.REDIRECT_URI}&response_type=code&scope=snsapi_userinfo&state=${to.path}&connect_redirect=1#wechat_redirect`;
    window.location.href = href;
    return false;
}
const checkToken = () => {
    let now = new Date().getTime();
    if (now > Number(localStorage.getItem('wxTokenTime'))) {
        localStorage.removeItem('wxToken');
        localStorage.removeItem('wxTokenTime');
    }
    return localStorage.getItem('wxToken')
}
const checkWhite = (to, form) => {
    if (to.path.includes('/login') || form.path.includes('/login')) {
        return true;
    }
    return false;
}
const setEntryUrl = (to) => {
    if (window.__wxjs_is_wkwebview) { // IOS
        if (window.entryUrl === '' || window.entryUrl === undefined) {
            window.entryUrl = `${window.location.origin}${to.fullPath}`; // 将后面的参数去除
        }
    }
}

router.beforeEach((to, form, next) => {
    window.scrollTo(0, 0);
    setEntryUrl(to);
    if (checkWhite(to, form)) {
        next();
        return false;
    }
    if (!checkToken()) {
        wechatLogin(to);
        return false;
    }
    next();

});

export default router