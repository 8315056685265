<template>
  <div id="Task">
    <div class="flex-column block">
      <div class="flex-row row">
        <div class="title">{{ info.title }}</div>
      </div>
      <div class="flex-row row">
        <img class="channel-image" v-bind:src="info.channel_icon" />
        <div class="flex-row row-center cooperation">
          {{ info.cooperation_zh }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column qr-row">
          <img class="qr-image" v-bind:src="info.source_link" />
        </div>
        <div class="flex-column">
          <div class="flex-row row2">
            <img class="icon-image" src="../assets/images/icon/brand.png" />
            <div class="row-text">产品品牌:{{ info.brand }}</div>
          </div>
          <div class="flex-row row2">
            <img class="icon-image" src="../assets/images/icon/user.png" />
            <div class="row-text">需求人数:{{ info.total_number }}</div>
          </div>
          <div class="flex-row row2">
            <img class="icon-image" src="../assets/images/icon/fans.png" />
            <div class="row-text">粉丝要求:{{ info.fans_number }}</div>
          </div>
          <div class="flex-row row2">
            <img class="icon-image" src="../assets/images/icon/time.png" />
            <div class="row-text">发稿时间:{{ info.release_time }}</div>
          </div>
          <div class="flex-row row2">
            <img class="icon-image" src="../assets/images/icon/labels.png" />
            <div class="row-text">达人领域:{{ info.labels }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-task",
  props: {
    info: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#Task {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.row-center {
  justify-content: center;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.block {
  width: 710px;
  background: #ffffff;
  border-radius: 16px;
  margin: 20px;
  justify-content: start;
  align-items: center;
  padding: 20px 0;
}
.row2 {
  width: 424px;
  margin-bottom: 20px;
}
.row {
  width: 634px;
  margin-bottom: 20px;
}
.qr-row {
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.title {
  width: 634px;
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1b1b1b;
  line-height: 50px;
  margin-top: 16px;
}
.row-text {
  font-size: 28px;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  line-height: 40px;
}
.lable {
  width: 634px;
  height: 50px;
  font-size: 36px;
  font-weight: 600;
  color: rgba(27, 27, 27, 1);
  line-height: 50px;
  justify-content: space-between;
}
.cooperation {
  width: 126px;
  height: 36px;
  background: rgba(3, 63, 255, 0.1);
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  color: rgba(3, 63, 255, 1);
  line-height: 34px;
  margin-left: 24px;
}
.content {
  width: 634px;
  margin-top: 20px;
  font-size: 28px;
  font-weight: 400;
  color: rgba(74, 74, 74, 1);
  line-height: 54px;
}
.icon-image {
  width: 40px;
  height: 40px;
}
.channel-image {
  width: 96px;
  height: 36px;
}
.product-image {
  width: 630px;
  margin: 1px 0;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 98px;
  background: rgba(255, 255, 255, 1);
  z-index: 10;
}
#launch-btn {
  z-index: 15;
}
.apply {
  width: 400px;
  height: 80px;
  background: rgba(3, 63, 255, 1);
  border-radius: 40px;
  font-size: 30px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 42px;
  margin-right: 30px;
}
.item {
  position: relative;
  width: 400px;
  height: 80px;
}
.qr-image {
  width: 200px;
  height: 200px;
}
</style>
