<template>
  <div v-if="!emptyState.show" class="header">
    <van-row justify="space-between" class="header-row">
      <van-col>
        <h1 class="header-title">{{ user.nickname }}</h1>
        <h2 class="header-desc">专属通告</h2>
      </van-col>
      <van-button color="rgba(3, 63, 255, 1)" size="small" plain url="/rules"
        >订阅设置</van-button
      >
    </van-row>
  </div>
  <van-list
    v-if="!emptyState.show"
    v-model:loading="taskState.loading"
    :finished="taskState.finished"
    finished-text="没有更多了"
    v-model:error="taskState.error"
    error-text="请求失败，点击重新加载"
    @load="onLoad"
  >
    <v-task v-for="info in taskState.taskList" :key="info.id" :info="info" />
  </van-list>
  <van-empty v-if="emptyState.show" :description="emptyState.description">
    <van-button color="rgba(3, 63, 255, 1)" size="small" url="/rules"
      >修改订阅设置</van-button
    >
  </van-empty>
</template>

<script>
import { Button, Row, Col, List, Empty } from "vant";
import Task from "@components/Task";
import { getTaskList, getUserInfo } from "@api/index";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Task.name]: Task,
    [List.name]: List,
    [Empty.name]: Empty,
  },
  setup() {
    const user = ref({});
    const router = useRouter();
    getUserInfo().then((response) => {
      console.log(response.data);
      user.value = response.data;
      if (response.data.rules_count == 0) {
          router.push("/rules/");
      }
    });
    const emptyState = reactive({
      show: false,
      description: "",
    });
    const taskState = reactive({
      taskList: [],
      page: 1,
      loading: false,
      finished: false,
      error: false,
    });
    const onLoad = () => {
      getTaskList({ page: taskState.page })
        .then((response) => {
          response.data.items.forEach((info) => {
            info.channel_icon = require(`./../assets/images/icon/${info.channel}.png`);
            taskState.taskList.push(info);
          });
          taskState.loading = false;
          taskState.page++;
          // 数据全部加载完成
          if (taskState.taskList.length >= response.data.total) {
            taskState.finished = true;
          }
          if (taskState.taskList == 0) {
            emptyState.show = true;
            emptyState.description = "当前订阅设置，暂无通告";
          }
          console.log(emptyState);
        })
        .catch(() => {
          taskState.loading = false;
          taskState.error = true;
        });
    };
    return {
      emptyState,
      taskState,
      onLoad,
      user,
    };
  },
};
</script>
<style >
.header {
  margin-left: 32px;
  width: 690px;
  margin-top: 20px;
}
.header-row {
  align-items: center;
}
.header-title {
  margin: 10px 0 10px;
  font-size: 38px;
  font-weight: normal;
}
.header-desc {
  margin: 0 0 10px;
  color: rgba(69, 90, 100, 0.6);
  font-size: 28px;
  font-weight: normal;
}
</style>
