<template>
  <div class="home">
    <div class="block">
      <van-row gutter="24">
        <van-col span="8" class="title">优势一</van-col>
      </van-row>
      <van-row class="content"
        ><img alt="Vue logo" src="../assets/logo.png"
      /></van-row>
    </div>
    <div class="block">
      <van-row gutter="24">
        <van-col span="8" class="title">优势二</van-col>
      </van-row>
      <van-row class="content"
        ><img alt="Vue logo" src="../assets/logo.png"
      /></van-row>
    </div>
    <div class="block">
      <van-row gutter="24">
        <van-col span="8" class="title">优势三</van-col>
      </van-row>
      <van-row class="content"
        ><img alt="Vue logo" src="../assets/logo.png"
      /></van-row>
    </div>
  </div>
  <v-task v-for="info in taskList" :key="info.id" :info="info" />
  <van-row justify="space-around" class="footer">
    <van-button round type="primary" url="/rules">查看更多</van-button>
  </van-row>
</template>

<script>
import { Row, Col, Button } from "vant";
import Task from "@components/Task";
import { getPreviewList } from "@api/index";
import { ref } from "vue";
export default {
  name: "Preview",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Task.name]: Task,
  },
  setup() {
    const taskList = ref([]);
    getPreviewList().then((response) => {
      let _taskList = response.data.items;
      _taskList.forEach((info) => {
        info.channel_icon = require(`./../assets/images/icon/${info.channel}.png`);
      });
      taskList.value = _taskList;
    });
    return {
      taskList,
    };
  },
};
</script>
<style >
.block {
  width: auto;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px;
}
.title {
  font-size: 18px;
  margin: 10px;
}
.content {
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer {
  width: 100%;
  position: fixed;
  bottom: 80px;
}
</style>
