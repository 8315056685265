import axios from 'axios';

const service = axios.create({
    // 设置超时时间
    timeout: 60000,
    baseURL: process.env.BASE_URL,
});

/**
 * 请求前拦截
 * 用于处理需要在请求前的操作
 */
service.interceptors.request.use(config => {
    const token = localStorage.getItem('wxToken');
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
}, (error) => {

    return Promise.reject(error);
});
/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
service.interceptors.response.use(response => {
    const responseCode = response.status;
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (responseCode === 200) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response.data);
    }
}, error => {
    // 服务器返回不是 2 开头的情况，会进入这个回调
    // 可以根据后端返回的状态码进行不同的操作
    console.log(error)
    localStorage.removeItem('wxToken');
    localStorage.removeItem('wxTokenTime');
    // // 跳转登录页
    window.location.replace('/')
        // return Promise.reject(error);
        // const responseCode = error.response.status;
        // switch (responseCode) {
        //     // 401：未登录
        //     case 401:
        //         localStorage.removeItem('wxToken');
        //         localStorage.removeItem('wxTokenTime');
        //         // // 跳转登录页
        //         router.push("/");
        //         break;
        //     // 403: token过期
        //     case 403:
        //         // 弹出错误信息
        //         // // 清除token
        //         localStorage.removeItem('wxToken');
        //         localStorage.removeItem('wxTokenTime');
        //         // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        //         setTimeout(() => {
        //             router.push("/");
        //         }, 500);
        //         break;
        //     // 404请求不存在
        //     case 404:
        //         // Message({
        //         // 	message: '网络请求不存在',
        //         // 	type: 'error'
        //         // });
        //         break;
        //     // 其他错误，直接抛出错误提示
        //     default:
        //     // Message({
        //     // 	message: error.response.data.message,
        //     // 	type: 'error'
        //     // });
        // }

    // return Promise.reject(error);
});

export default service;