import service from './base';

/**
 * 获取基础数据
 * @param {*} data 
 */
export function getBaseInfo(data = {}) {
    return service.request({
        url: 'web/base/info',
        method: 'GET',
        params: data
    });
}

export function getTaskList(params) {
    return service.request({
        url: 'web/task/list',
        method: 'GET',
        params: params
    });
}
export function getPreviewList() {
    return service.request({
        url: 'web/task/list',
        method: 'GET',
    });
}

export function getRecordsInfo(data) {
    return service.request({
        url: 'web/records/info',
        method: 'GET',
        params: data
    });
}

export function saveRules(data) {
    return service.request({
        url: 'web/rules/save',
        method: 'POST',
        data: data
    });
}
export function getRulesInfo() {
    return service.request({
        url: 'web/rules/info',
        method: 'GET',
    });
}

export function getUserInfo(data) {
    return service.request({
        url: 'web/user/info',
        method: 'GET',
        params: data
    });
}

export function login(data) {
    return service.request({
        url: 'web/login',
        method: 'GET',
        params: data
    });
}